var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AceEditor',{attrs:{"lang":_vm.language,"theme":"monokai","width":"100%","height":_vm.height,"options":{
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    fontSize: 14,
    highlightActiveLine: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
    showPrintMargin: false,
    showGutter: true,
  }},on:{"init":_vm.editorInit},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})}
var staticRenderFns = []

export { render, staticRenderFns }