





















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

@Component({ components: {} })
export default class CodeEditor extends Vue {
  @VModel()
  content!: string;

  @Prop({ default: "100vh" })
  height!: string;

  @Prop({ default: "php" })
  language!: string;

  editorInit() {
    require("brace/ext/language_tools"); //language extension prerequsite...
    require("brace/theme/monokai");
    require("brace/mode/php");
    require("brace/mode/css");
    require("brace/mode/html");
  }
}
